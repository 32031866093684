// Import font from Google Font ("Montserrat" font example)
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primary-color: #4abdac;
$primary-color-light1: #cde4df;
$primary-color-light2: #7ea8a0;
$primary-color-dark1: #4f6460;
$primary-color-dark2: #20423d;
$primary-color-dark3: #0b2e27;

$grey1: #dee2e6;
$grey2: #757575;
$grey3: #343a40;

$white: #f4f8f6;
$black: #0f1010;

$default-font-family: "Arial", sans-serif;
$font-family: "Montserrat";

$success: #4caf50;
$alert: #f44336;

@mixin background-style1() {
  background-color: $primary-color-light1;
  background: linear-gradient(rgba($primary-color, 0.95), rgba(#cde4df, 0.95)),
    url("./img/background1.jpg") no-repeat center center fixed;
  background-attachment: fixed;
  background-size: cover;
}
@mixin background-style2() {
  background-color: $primary-color-light1;
  background: linear-gradient($primary-color-dark1, rgba($primary-color, 0.95)),
    url("./img/background2.png") no-repeat center center fixed;
  background-attachment: fixed;
  background-size: cover;
}

@mixin titleH2($size) {
  font-size: $size;
  letter-spacing: 7px;
  line-height: 1.3;
  font-weight: 600;
  color: $primary-color;
}
